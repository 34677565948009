<template>
  <div class="card">
    <div class="card-title">
      <span>{{ title }}</span>
      <div v-if="showRightBtn" class="row_new" style="margin-left: auto;" >
        <el-button type="primary" size="small" @click="toAdd">{{ rightBtnText }}</el-button>
      </div>
      <div class="card-title_extra">
        <slot name="extra"></slot>
      </div>
    </div>
    <div :class="{ 'padding-30': padding !== 'none' }" class="card-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "easy-card",
  props: {
    title: String,
    padding: String,
    showRightBtn: {
      type: Boolean,
      default: false,
    },
    rightBtnText: {
      type: String,
      default: '新增',
    },
  },
  methods: {
    toAdd(){
      this.$emit("addItem");
    }
  },
};
</script>

<style lang="less" scoped>
.card {
  .card-title {
    border-bottom: 1px solid #f2f2f2;
    // padding: 10px;
    //width: 100%;
    height: 50px;
    font-size: 15px;
    font-weight: bold;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    // background-color: rgba(0, 145, 67, 0.04);
    background-color: #ECF3FF;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      //top: 12px;
      width: 2px;
      height: 50px;
      background-color: #0768FD;
    }

    span{
      margin-left: 20px;
      font-weight: bold;
      //font-size: 14px;
    }

    .row_new{
      margin-right: 20px;
    }
  }

  .card-content {
    &.padding-30 {
      padding: 30px 0;
    }
  }
}
</style>
