<template>
    <div>
      <!-- <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click" /> -->
      <!-- <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click" /> -->
      <img class="full-icon" v-if="!isFullscreen" src="@/assets/images/screenFull.png" @click="click" alt="">
      <img class="full-icon" v-else src="@/assets/images/screenUnFull.png" @click="click" alt="">
    </div>
  </template>
  
  <script>
  import screenfull from 'screenfull'
  
  export default {
    name: 'Screenfull',
    data() {
      return {
        isFullscreen: false
      }
    },
    mounted() {
      this.init()
    },
    beforeDestroy() {
      this.destroy()
    },
    methods: {
      click() {
        if (!screenfull.isEnabled) {
          this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
          return false
        }
        screenfull.toggle()
      },
      change() {
        this.isFullscreen = screenfull.isFullscreen
      },
      init() {
        if (screenfull.isEnabled) {
          screenfull.on('change', this.change)
        }
      },
      destroy() {
        if (screenfull.isEnabled) {
          screenfull.off('change', this.change)
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .screenfull-svg {
    display: inline-block;
    cursor: pointer;
    fill: #5a5e66;;
    width: 20px;
    height: 20px;
    vertical-align: 10px;
  }
  .full-icon{
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
  </style>
  