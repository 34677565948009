import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//查询套餐列表并分页
export function getLeaseInfoListForPage(parkingId, pageSize, leaseStatus, leaseTitle, buyWay, pageNo, isExport, exportSize) {
    return request.get('/leaseController/getLeaseInfoListForPage?parkingId=' + parkingId + '&pageSize=' + pageSize + '&leaseStatus=' + leaseStatus + '&leaseTitle=' + leaseTitle + '&buyWay=' + buyWay + '&pageNo=' + pageNo + '&isExport=' + isExport + '&exportSize=' + exportSize)
}

//根据主键编辑套餐
export function updateLeaseInfo(id) {
    return request.get('/leaseController/updateLeaseInfo?id=' + id)
}

//根据id查询套餐详情
export function getLeaseInfoById(id) {
    return request.get('/leaseController/getLeaseInfoById?id=' + id)
}

//停用或启用套餐
export function disableOrStartUsingLeaseInfo(id, status) {
    return request.get('/leaseController/disableOrStartUsingLeaseInfo?id=' + id + '&status=' + status)
}

//添加长租套餐
export function addOrUpdateLeaseInfo(leaseinfo) {
    return request.post('/leaseController/addOrUpdateLeaseInfo', leaseinfo)
}

//根据ID获取订单详情
export function getLeaseOrderById(id) {
    return request.get('/leaseController/getLeaseOrderById?id=' + id)
}

//订单审核
export function leaseOrderVerify(id, reason, auditStatus) {
    return request.get('/leaseController/leaseOrderVerify?id=' + id + '&reason=' + reason + '&auditStatus=' + auditStatus)
}

//获取长租订单列表并分页
export function getLeaseOrderListForPage(autoId, leaseId, pageSize, pageNo, orderCode) {
    return request.get('/leaseController/getLeaseOrderListForPage?autoId=' + autoId + '&leaseId=' + leaseId + '&pageSize=' + pageSize + '&pageNo=' + pageNo + '&orderCode=' + orderCode)
}

//执行或关闭活动套餐
export function startUsingOrDisable(id, status) {
    return request.get('/leaseController/startUsingOrDisable?id=' + id + '&status=' + status)
}

//添加活动套餐
export function saveLeaseDisCount(data) {
    return request.post('/leaseController/saveLeaseDisCount', data)
}

//根据活动ID查询参加活动的长租订单
export function getLeaseDisCountOrder(activityId, page, size) {
    return request.get('/leaseController/getLeaseDisCountOrder?activityId=' + activityId + '&page=' + page + '&size=' + size)
}

//根据ID获取长租活动详情
export function getLeaseDisCountById(id) {
    return request.get('/leaseController/getLeaseDisCountById?id=' + id)
}

//查询长租活动并分页
export function getLeaseDisCountListForPage(parkingId, pageSize, leaseId, activityName, activityStatus, pageNo, isExport, exportSize) {
    return request.get('/leaseController/getLeaseDisCountListForPage?parkingId=' + parkingId + '&pageSize=' + pageSize + '&leaseId=' + leaseId + '&activityName=' + activityName + '&activityStatus=' + activityStatus + '&pageNo=' + pageNo + '&isExport=' + isExport + '&exportSize=' + exportSize)
}

//按车场Id查询当前车场套餐
export function getLeaseInfoByParkingLotId(parkingLotId) {
    return request.get('/leaseController/getLeaseInfoByParkingLotId?parkingLotId=' + parkingLotId)
}

//解析长租模板
export function doUploadAnalysisExcel(params) {
    return request.post('/leaseController/doUploadAnalysisExcel', params);
}

//批量导入长租信息
export function batchImportLeaseList(params) {
    return request.post('/leaseController/batchImportLeaseList', params)
}

//查询长租订单列表
export function getLeaseOrderForPage(params) {
    return request.get('/leaseController/getLeaseOrderForPage?' + objectToQueryString(params));
}

//创建长租订单
export function createLeaseOrder(data) {
    return request.post('/leaseController/createLeaseOrder', data);
}

export function publishCouponByQRCode(params) {
    return request.post('openController/publishCouponByQRCode', params);
}


export function leaseRenewalOrder(params) {
    return request.post('leaseController/leaseRenewalOrder', params);
}

//删除长租订单
export function deleteLeaseOrderById(id) {
    return request.post('leaseController/deleteLeaseOrderById?id=' + id);
}

//编辑长租订单
export function updateLeaseOrder(data) {
    return request.post('/leaseController/updateLeaseOrder', data);
}

















// 长租套餐列表
export function getLeaseList(params){
    return request.post('/lease/leaseList?' + objectToQueryString(params));
}

// 长租套餐详情
export function getLeaseInfo(params){
    return request.post('/lease/leaseInfo?' + objectToQueryString(params));
}

// 长租套餐新增
export function addLease(params){
    return request.post('/lease/addLease',params);
}

// 长租套餐编辑
export function editLease(params){
    return request.post('/lease/editLease', params);
}

// 长租套餐停用
export function statusLease(params){
    return request.post('/lease/stopLease?' + objectToQueryString(params));
}

// 查询活动列表
export function getLeaseActivityList(params) {
    return request.post('/lease/leaseActivityList?' + objectToQueryString(params));
}

// 活动详情
export function getLeaseActivityInfo(params) {
    return request.post('/lease/leaseActivityInfo?' + objectToQueryString(params));
}

// 新增活动
export function addLeaseActivity(params) {
    return request.post('/lease/addLeaseActivity?' + objectToQueryString(params));
}

// 活动购买记录
export function getLeaseBuyRecordList(params) {
    return request.post('/lease/leaseActivityBuyRecordList?' + objectToQueryString(params));
}

// 订单列表
export function getLeaseOrderList(params) {
    return request.post('/lease/leaseOrderList?' + objectToQueryString(params));
}

// 订单详情
export function getLeaseOrderInfo(params) {
    return request.post('/lease/leaseOrderInfo?' + objectToQueryString(params));
}

// 订单新建
export function addLeaseOrder(params) {
    return request.post('/lease/addLeaseOrder?' + objectToQueryString(params));
}

// 订单编辑
export function editLeaseOrder(params) {
    return request.post('/lease/editLeaseOrder?' + objectToQueryString(params));
}

// 下载模板
export function downLoadTemplate() {
    return request.post('/lease/downLoadTemplate');
}

// 上传
export function upLodeFileLeaseOrder(params){
    return request.post('/lease/upLodeFileLeaseOrder' , params);
}

// 批量创建
export function batchAddLeaseOrder(params){
    return request.post('/lease/batchAddLeaseOrder?' + objectToQueryString(params));
}

// 订单停用
export function statusLeaseOrder(params) {
    return request.post('/lease/stopLeaseOrder?' + objectToQueryString(params));
}



// 预约列表
export function getPreParkingLotList(params) {
    return request.post('/pre/preParkingLotList?' + objectToQueryString(params));
}

// 预约新增
export function addPreParkingLot(params) {
    return request.post('/pre/addPreParkingLot?' + objectToQueryString(params));
}

// 预约删除
export function delPreParkingLot(params) {
    return request.post('/pre/delPreParkingLot?' + objectToQueryString(params));
}


// 黑名单列表
export function getBlackAndWhiteList(params) {
    return request.post('/blackAndWhite/blackAndWhiteList?' + objectToQueryString(params));
}

// 新增黑白名单
export function addBlackAndWhite(params) {
    return request.post('/blackAndWhite/addBlackAndWhite?' + objectToQueryString(params));
}

// 黑名单删除
export function delBlackAndWhite(params) {
    return request.post('/blackAndWhite/delBlackAndWhite?' + objectToQueryString(params));
}

// 黑名单上传
export function upLodeFileBlackAndWhite(params) {
    return request.post('/blackAndWhite/upLodeFileBlackAndWhite',params);
}

// 黑名单批量创建
export function batchAddBlackAndWhite(params) {
    return request.post('/blackAndWhite/batchAddBlackAndWhite?' + objectToQueryString(params));
}
