<template>
    <div>
        <el-date-picker v-model="selectVal" type="daterange" :picker-options="pickerOptions" range-separator="-"
            @change="changeDate" :clearable="clearable" value-format="yyyy-MM-dd" start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
    </div>
</template>

<script>
export default {
    name: "date-picker",
    props: {
        value: {
            type: [String, Array],
            default: ''
        },
        clearable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            selectVal: this.value,
            pickerMinDate: "",
            pickerMonth: null,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * 92
                        || time.getTime() > new Date().getTime();
                },
                // onPick: ({ maxDate, minDate }) => {
                //     this.pickerMonth = new Date(minDate).getMonth();
                //     this.pickerMinDate = minDate.getTime()
                //     if (maxDate) {
                //         this.pickerMinDate = ''
                //     }
                // },
                // disabledDate: (time) => {
                //     if (this.pickerMinDate !== '') {
                //         const monthDay3 = [92, 92, 90, 90, 89, 92, 91, 92, 92, 92, 92, 91];
                //         const day = monthDay3[this.pickerMonth];
                //         const dayTime = (day - 1) * 24 * 3600 * 1000;
                //         let maxTime = this.pickerMinDate + dayTime
                //         let minTime = this.pickerMinDate - dayTime
                //         // if (maxTime > new Date()) {
                //         //     maxTime = new Date()
                //         // }
                //         return time.getTime() > maxTime || time.getTime() < minTime
                //     }
                //     // return time.getTime() > Date.now()
                //     return null;
                // },
                shortcuts: [
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            const monthDay = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,];
                            const day = monthDay[new Date(end).getMonth()];
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * (day - 1));
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            const monthDay3 = [92, 92, 90, 90, 89, 92, 91, 92, 92, 92, 92, 91];
                            const day = monthDay3[new Date(end).getMonth()];
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * (day - 1));
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
        };
    },
    watch: {
        value(newVal) {
            this.selectVal = !newVal || newVal === "" ? "" : newVal;
        },
    },
    methods: {
        changeDate(e) {
            this.$emit('changeValue', e)
        }

    },
    mounted() {
        // const monthDay = [28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31, 31];
        this.pickerMonth = new Date().getMonth();
        // console.log(this.pickerMonth);
        const monthDay3 = [92, 92, 90, 90, 89, 92, 91, 92, 92, 92, 92, 91];
        const day = monthDay3[this.pickerMonth];
        this.pickerOptions = {
            disabledDate(time) {
                return time.getTime() < new Date().getTime() - 24 * 3600 * 1000 * day
                    || time.getTime() > new Date().getTime();
            },
            shortcuts: [
                {
                    text: "最近一周",
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近一个月",
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        const monthDay = [31, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30];
                        const day = monthDay[new Date(end).getMonth()];
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * (day - 1));
                        picker.$emit("pick", [start, end]);
                    },
                },
                {
                    text: "最近三个月",
                    onClick(picker) {
                        // var dateTime = new Date();
                        // dateTime = dateTime.setMonth(dateTime.getMonth() - 3);
                        // dateTime = new Date(dateTime);
                        const end = new Date();
                        const start = new Date();
                        const monthDay3 = [92, 92, 90, 90, 89, 92, 91, 92, 92, 92, 92, 91];
                        const day = monthDay3[new Date(end).getMonth()];
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * (day - 1));
                        picker.$emit("pick", [start, end]);
                    },
                },
            ],
        }

    },
};
</script>

<style lang="less" scoped></style>