import Vue from 'vue'
import VueRouter from "vue-router";
import Router from "vue-router";
import Layout from '@/layouts'
// import ShareLayout from '@/layouts/share'
// import NetLayout from '@/layouts/net'
import CouponQrcode from '@/pages/coupon_manage/coupon_qrcode'
import CouponQrcodeResult from '@/pages/coupon_manage/coupon_qrcode_result'
import AppDownload from '@/pages/AppDownload';
import DataBoard from '@/pages/data_board';
import Routes from './routes'
// import ShareRoutes from './share_routes';
// import NetRoutes from './net_routes';


Vue.use(Router)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        {
            path: '/login',
            component: () => import('@/pages/Login'),
            hidden: true
        },
        {
            path: '/searchcar',
            component: () => import('@/pages/pay/Search.vue'),
            hidden: true
        },
        {
            path: '/SearchCurOrder',
            component: () => import('@/pages/pay/SearchCurOrder.vue'),
            hidden: true
        },
        {
            path: '/searchInner',
            component: () => import('@/pages/pay/SearchInner.vue'),
            hidden: true
        },
        {
            path: '/phonedrivein',
            component: () => import('@/pages/pay/PhoneDriveIn.vue'),
            hidden: true
        },
        {
            path: '/phonedriveout',
            component: () => import('@/pages/pay/PhoneDriveOut.vue'),
            hidden: true
        },
        {
            path: '/payment',
            component: () => import('@/pages/pay/Payment.vue'),
            hidden: true
        },
        {
            path: '/leasepayment',
            component: () => import('@/pages/pay/LeaseOrderPayment.vue'),
            hidden: true
        },
        {
            path: '/searchcoupon',
            component: () => import('@/pages/coupon/SearchCoupon.vue'),
            hidden: true
        },
        {
            path: '/sendCouponDetail',
            component: () => import('@/pages/coupon/SendCouponResult.vue'),
            hidden: true
        },
        {
            path: '/screen',
            component: () => import('@/pages/screen'),
            hidden: true
        },
        {
            path: '/404',
            component: () => import('@/pages/404'),
            hidden: true
        },
        {
            path: '/main',
            name: 'main',
            component: () => import('@/pages/Main'),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/',
            component: Layout,
            redirect: '/home',
            children: Routes
        },
        // {
        //     path: '/share',
        //     component: ShareLayout,
        //     redirect: '/share/parking_manage/share_parkingList',
        //     children: ShareRoutes
        // },
        // {
        //     path: '/net',
        //     component: NetLayout,
        //     redirect: '/net/parking_manage/net_parkingList',
        //     children: NetRoutes
        // },
        {
            path: '/coupon_qrcode',
            component: CouponQrcode,
        },
        {
            path: '/coupon_qrcode_result',
            component: CouponQrcodeResult,
        },
        {
            path: '/app_download',
            component: AppDownload
        },
        {
            path: '/data_board',
            component: DataBoard
        }
    ]
})

export default router
