<template>
  <div>
    <!--    带二级菜单-->
    <el-submenu class="border-radius4" v-if="item.children && item.children.length > 0"
      :class="item.parentId ? 'childMenu' : ''" :key="item.menuurl" :index="item.menuurl">
      <template slot="title">
        <i v-if="isIcon(item.extendInfo)" :class="item.extendInfo"></i>
        <i v-else :class="item.extendInfo" class="iconfont"></i>
        <!-- <Item :icon="item.extendInfo" /> -->
        <span class="menu-name">{{ item.permissionName }}</span>
      </template>

      <menu-item class="border-radius4" v-for="(route, index) in item.children" :key="index" :item="route" />
    </el-submenu>
    <!--    不带二级菜单的一级菜单或二级菜单 一级菜单带圆角-->
    <el-menu-item :class="item.parentId == '' ? 'border-radius4' : 'border-radius4'" v-else :key="item.menuurl"
      :index="item.menuurl" :route="item">
      <!-- <i :class="item.extendInfo" v-if="isIcon(item.extendInfo)"></i>
      <svg-icon
        :iconClass="item.extendInfo"
        className="el-icon-svg_icon"
        v-else
      /> -->
      <!--      <i :class="item.extendInfo" v-if="isIcon(item.extendInfo)"></i>-->
      <!--      <i class="iconfont" v-else :class="item.extendInfo"></i>-->
      <!--      <view class="iconfont"></view>-->
      <i v-if="isIcon(item.extendInfo)" :class="item.extendInfo"></i>
      <i v-else :class="item.extendInfo" class="iconfont"></i>
      <!-- <Item :icon="item.extendInfo" /> -->
      <span v-if="item.parentId == ''" slot="title" class="menu-name">{{ item.permissionName }}</span>
      <span v-else slot="title" class="sub-menu-name">{{ item.permissionName }}</span>

    </el-menu-item>
  </div>
</template>

<script>
import path from "path";
// import Item from './item'

export default {
  components: {
    // Item
  },
  name: "menu-item",
  props: {
    menuList: Array,
    basePath: {
      type: String,
      default: "",
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
  },
  methods: {
    getImageURL(icon) {
      return require(`@/assets/images/menu/${icon}.png`);
    },
    isIcon(icon) {
      return icon.includes("el-icon");
    },
    resolvePath(routePath) {
      console.log(path.resolve(this.basePath, routePath));
      return path.resolve(this.basePath, routePath);
    },
  },
};
</script>

<style lang="less" scoped>
.el-icon-svg_icon {
  color: #fff;
  font-size: 18px;
  vertical-align: -5px !important;
  margin-right: 5px;
}

.iconfont {
  //margin-right: 5px;
  font-size: 18px;
}

.el-submenu {

  //color: red;
  i {
    font-size: 21px;
  }
}

//.el-submenu.is-active.is-open {
//  background-color: #0768FD !important;//选中背景色
//  color: #fff;//选中颜色
//  span {
//    color: #fff !important;
//  }
//}

.el-submenu__title {
  i {
    color: #5F666C;
  }
}

.el-menu-item i {
  color: #5F666C;
}



.el-submenu /deep/ .el-submenu__title {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  // font-weight: bold;
  // padding-left: 23px;
  //display: flex;
  //align-items: center;
  //justify-content: center;

  //background: red;

  &.is-active {
    //background: red;
  }
}

/deep/ .el-submenu.is-active>.el-submenu__title {
  height: 40px;
  line-height: 40px;
  color: #545D67 !important;
  background: #CAE0FF;
  font-family: Hiragino Sans GB !important;

  i {
    color: #5F666C !important;
  }
}

// .el-submenu.is-active.is-opened /deep/ .el-submenu__title {
//   height: 40px;
//   line-height: 40px;
//   // color: #0768FD !important;
//   color: #545D67 !important;
//   background-color: #CAE0FF;
//   // font-family:Helvetica Neue;
//   font-family: Hiragino Sans GB !important;

//   // font-weight: 400;
//   i {
//     // color: #0768FD !important;
//     color: #5F666C !important;

//   }
// }

.el-menu--collapse .el-submenu.is-active /deep/ .el-submenu__title {
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: center;
  //color: #0768FD !important;
  background: #0768FD !important;
  border-radius: 4px;

  i {
    color: white !important;
  }
}

.el-menu--collapse .el-submenu /deep/ .el-submenu__title {
  height: 40px;
  line-height: 40px;
  display: flex !important;
  justify-content: center !important;
  //color: #0768FD !important;
  //background: #0768FD !important;
  border-radius: 4px;

  i {
    color: #5F666C !important;
  }
}

// 父级菜单的hover
.el-submenu /deep/ .el-submenu__title:hover {
  background-color: #ECF3FF !important; //选中背景色
  // background-color: #41a3fb !important;
}

/deep/ .el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  // font-weight: bold;
  padding-left: 23px;

  i {
    font-size: 21px;
  }
}

.el-submenu /deep/ .el-menu-item {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  // font-weight: bold;
  padding-left: 23px !important;
}

.el-menu-item:hover {
  background-color: #ECF3FF !important; //选中背景色
}

/deep/ .el-submenu__title .el-submenu__title {
  margin: 0.52083vw auto !important;
}

// 二级菜单选中样式
.el-menu-item.is-active {
  //border-radius: 4px;
  background-color: #0768FD !important; //选中背景色
  color: #fff !important; //选中颜色

  span {
    color: #fff !important;
  }

  i {
    color: #fff !important;
  }

  // .svg-icon{
  //   color: #fff !important;
  //   fill:#fff !important;
  // }
}

/deep/ .menu-name {
  margin-left: 19px;
}

/deep/ .sub-menu-name {
  margin-left: 37px;
}

/deep/ .childMenu .el-submenu__title {
  padding-left: 40px !important; // 含子菜单非一级目录样式
}

///deep/ .is-active .el-submenu__title{
//  background-color: #0768FD !important;//选中背景色
//}</style>
